<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Sandpiper Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/sandpiper/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/sandpiper/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/sandpiper/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/sandpiper/favicons/manifest.json', type: 'application/json' },
]
const scripts: Array<any> = [
  {
    children: '(function(){ var s = document.createElement(\'script\'); var h = document.querySelector(\'head\') || document.body; s.src = \'https://acsbapp.com/apps/app/dist/js/app.js\'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : \'\', footerHtml : \'\', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : \'en\', position : \'right\', leadColor : \'#146FF8\', triggerColor : \'#146FF8\', triggerRadius : \'50%\', triggerPositionX : \'right\', triggerPositionY : \'bottom\', triggerIcon : \'people\', triggerSize : \'bottom\', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : \'small\', triggerPositionX : \'right\', triggerPositionY : \'bottom\', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : \'20\' } }); }; h.appendChild(s); })();',
    body: true,
  },
]

if (!isVoixTemplatizer)
  setupHeader(null, links, scripts)

const { currentResort } = await getCommonLayoutData(
  isVoixTemplatizer,
  [
    'resorts',
    'brands',
    'footerCompanyMenu',
    'footerResourcesMenu',
    'footerTermsMenu',
  ],
)

const { data: footerTermsMenu } = await $voixNuxtApi(`/api/sites/42/menus/Sandpiper%20Footer%20Terms%20Menu`)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#cb9110' })
</script>

<template>
  <div
    class="text-grey-darker bg-abs-white font-sandpiper-sanserif"
  >
    <div id="portal-destination" transition="fade-transition" />

    <SandpiperNavbar :menu="currentResort?.data?.menu" />
    <slot />

    <SandpiperFooter :footer-terms-menu="footerTermsMenu" />
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/sandpiper.scss';
</style>
